/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import Flex from './Flex';
import Card from '../../components/Card';
import Divider from '../../components/Divider';
import ContinueButton from '../../components/ContinueButton';
import InputGroup from '../../components/InputGroup';
import { ErrorMessageContainer } from '../../components/Form';

import { mq } from '../../styles/breakpoints';
import {
  useWizardState,
  useWizardProps,
} from '../../state';

import {
  getSignUpPackageAddons,
  getPackageDisplayName,
  getPackagePrice,
  getIncludedPackageAddons,
  getPackageAddonPrice,
  calculatePackageAddonsPrice,
} from '../../state/packages';


const Addendum = styled.span`
  display: inline-block;
  margin-bottom: 1em;
  color: #787878;
  color: ${props => props.color || '#787878' };
  font-size: 0.8rem;
  line-height: 24px;
`;


const SummaryDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;

  ${mq.sm} {
    width: 100%;
    text-align: left;
    margin-top: 1em;
  }
`;


const SummaryDetails = () => {
  const {
    userDetails,
    mlsID,
    siteInfo,
  } = useWizardState();

  return (
    <SummaryDetailsContainer>
      <Flex row={false} alignItems="flex-start">
        <span>{ `${userDetails.firstName} ${userDetails.lastName}` }</span>
        <span>{ userDetails.email }</span>
        { mlsID && <span>MLS #{ mlsID }</span> }
        <span>{ siteInfo.site_domain }</span>
      </Flex>
    </SummaryDetailsContainer>
  );
};

const getDate30DaysFromToday = () => {
  const result = new Date();
  result.setDate(result.getDate() + 30);
  return result;
};

const hasValue = (value) => {
  return value !== null && Number.isInteger(value) && value > -1;
}

const isZero = (value) => {
  return hasValue(value) && value === 0;
}

const mdCardPadding = css`
  @media screen and (min-width: 1300px) {
    padding: 5em 7em 7em 7em;
  }
`;

const SignUpFee = (props) => {
  const { packages } = useWizardProps();
  if (props.billingPlan === 'monthly') {
    return (
      <React.Fragment>
        <Flex directionSm="row" justifySm="space-between">
          <h3 css={css`font-weight: normal`}>Sign-Up Fee</h3>
          <span>${props.signUpFee || 250}</span>
        </Flex>
        <Addendum>Includes MLS approval and kick-off call</Addendum>
      </React.Fragment>
    )
  } else if (props.billingPlan === 'annual') {
    return (
      <React.Fragment>
        <Flex directionSm="row" justifySm="space-between">
          <h3 css={css`font-weight: normal`}>Yearly Platform Fee</h3>
          <span>${3289}</span>
        </Flex>
        <Addendum>Includes MLS approval and kick-off call</Addendum>
      </React.Fragment>
    )
  } else {
    // Package Pricing

    // Get the addons that are included and selected
    let includedSignUpAddons = [];
    Object.entries(props.addons).forEach(([key, value]) => {
      if (value === true) {
        const getPackageAddon = getSignUpPackageAddons(packages, props.billingPlan).find((p) => p.name === key);
        if (getPackageAddon) {
          includedSignUpAddons.push(getPackageAddon.display);
        }
      }
    });

    return (
      <React.Fragment>
        <Flex directionSm="row" justifySm="space-between">
          <h3 css={css`font-weight: normal`}>Sign-Up Fee</h3>
          <span>${props.packageSignUpFee}</span>
        </Flex>
        <Addendum>
          Includes MLS approval and kick-off call
          <br />
          Includes {includedSignUpAddons.join(', ')}
        </Addendum>
      </React.Fragment>
    )
  }
}

const Subtotal = (props) => {
  if (props.billingPlan === 'monthly') {
    return (
      <Flex margin="1em 0em 1em 0em" directionSm="row" justifySm="space-between">
        <h3>Subtotal</h3>
        <span>${(props.signUpFee || 250)}</span>
      </Flex>
    )
  } else if (props.billingPlan === 'annual') {
    return (
      <Flex margin="1em 0em 1em 0em" directionSm="row" justifySm="space-between">
        <h3>Subtotal</h3>
        <span>$0</span>
      </Flex>
    )
  } else {
    // Package Pricing
    return (
      <Flex margin="1em 0em 1em 0em" directionSm="row" justifySm="space-between">
        <h3>Subtotal</h3>
        <span>${props.packageSignUpFee}</span>
      </Flex>
    )
  }
}

const DueToday = (props) => {
  if (props.billingPlan === 'monthly') {
    return (
      <Flex directionSm="row" justifySm="space-between">
        <h3>Due Today *<Addendum>(plus tax, if applicable)</Addendum></h3>
        <h3>${( props.promoAmount || props.signUpFee )}</h3>
      </Flex>
    )
  } else if (props.billingPlan === 'annual') {
    return (
      <Flex directionSm="row" justifySm="space-between">
        <h3>Due Today *<Addendum>(plus tax, if applicable)</Addendum></h3>
        <h3>$3289</h3>
      </Flex>
    )
  } else {
    // Package Pricing
    return (
      <Flex directionSm="row" justifySm="space-between">
        <h3>Due Today *<Addendum>(plus tax, if applicable)</Addendum></h3>
        <h3>${( props.promoAmount || props.packageSignUpFee )}</h3>
      </Flex>
    )
  }
}

const ExpectedMonthlyFees = (props) => {
  const { packages } = useWizardProps();
  if (props.billingPlan === 'monthly') {
    return (
      <div css={css`${mq.sm} { width: 100%; }`}>
        <Flex directionSm="row" justifySm="space-between">
          <p>Monthly Platform Fee</p>
          <h4>$299</h4>
        </Flex>

        <Addendum>
          (Includes 2 users)
          <br />
          (First Payment Billed on { props.firstBillingDate })
        </Addendum>
      </div>
    )
  } else if (props.billingPlan === 'annual') {
    // Render nothing for annual, this was the existing behavior but we need to return something
    // because this is a component

    return(<div></div>);
  } else {
    // Package Pricing

    // Get the addons that are included in the package
    let includedPackageAddonsDisplay = [];
    getIncludedPackageAddons(packages, props.billingPlan).forEach((addon) => {
      includedPackageAddonsDisplay.push(addon.display);
    });

    return (
      <div css={css`${mq.sm} { width: 100%; }`}>
        <Flex directionSm="row" justifySm="space-between">
          <p>{getPackageDisplayName(packages, props.billingPlan)} - {props.commitment} month</p>
          <h4>${getPackagePrice(packages, props.billingPlan, props.commitment)}</h4>
        </Flex>

        <Addendum>
          { includedPackageAddonsDisplay.length > 0 && (
            <React.Fragment>
              Includes {includedPackageAddonsDisplay.join(', ')}
            </React.Fragment>
          )}
        </Addendum>
      </div>
    )
  }
}

const prorateDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 14);

  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const calculateProrationAmount = (billingPlan, commitment, addons) => {
  const { packages } = useWizardProps();
  const packageCost = getPackagePrice(packages, billingPlan, commitment);
  const addonsCost = calculatePackageAddonsPrice(packages, billingPlan, addons, 'monthly');
  const totalCost = packageCost + addonsCost;
  const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
  const prorationDays = 14;
  const prorationAmount = (totalCost / daysInMonth) * prorationDays;
  const roundedProrationAmount = Math.floor(prorationAmount * 100) / 100;

  return `$${roundedProrationAmount.toFixed(2)}`;
};

export default function OrderSummary({
  onValidPromo,
  promoCode,
  validatePromo,
  setValidatePromo,
  mustApplyPromoError,
  setMustApplyPromoError,
}) {
  const {
    currentPrice,
    addons,
    billingPlan,
    targetMarket,
    packageSignUpFee,
    commitment
  } = useWizardState();

  const {
    orderUrl,
    signUpFee,
    checkPromoUrl,
    packages,
  } = useWizardProps();

  const { handleSubmit, register, errors, watch, triggerValidation } = useForm({
    validateCriteriaMode: 'all',
    nativeValidation: true,
    mode: 'onBlur',
  });

  const [promoAmount, setPromoAmount] = useState(null); // promo amount of null means no promo
  const [invalidPromo, setInvalidPromo] = useState(false);

  const onPromoApply = handleSubmit((d) => {
    const { promo_code } = d;

    if (!promo_code) {
      return;
    }

    $.get(JSON.parse(checkPromoUrl), { code: promo_code })
      .done((res) => {
        const { signup_fee } = res;

        if (signup_fee === JSON.parse(signUpFee)) {
          setInvalidPromo(true);
        }

        else {
          setPromoAmount(signup_fee);
          setInvalidPromo(false);
          setMustApplyPromoError(false);
          onValidPromo(promo_code);
        }
      })
      .fail((res) => {
        setInvalidPromo(true);
      });
  });

  const displayMMFee = useMemo(() => {
    if (addons.google && addons.fb) {
      return 300 * 2 - 100; // NOTE: $100 discount for doing both
    }

    if (addons.google || addons.fb) {
      return 300; // NOTE: Individually they each cost $100
    }

    return null;
  }, [addons]);

  const firstBillingDate = useMemo(() => {
    const billingDate = getDate30DaysFromToday();
    const month = billingDate.getUTCMonth() + 1;
    const day = billingDate.getUTCDate();
    const year = billingDate.getUTCFullYear();

    return `${month}/${day}/${year}`;
  }, []);

  useEffect(() => {
    if (validatePromo) {
      triggerValidation('promo_code');
      setValidatePromo(false);
    }
  }, [validatePromo]);

  const handleUserKeyPress = e => {
    setInvalidPromo(false);

    if (e.key === 'Enter' && !e.shiftKey) {
      onPromoApply();
    }
  };

  return (
    <Card css={mdCardPadding} className="orderSummary">
      <h1 css={css`margin-bottom: 0.5em;`}>Order Summary</h1>

      <SummaryDetails />

      <Divider light />

      <div className="signUpFee" css={css`${mq.sm} { width: 100%; }`}>
        <SignUpFee
          signUpFee={signUpFee}
          billingPlan={billingPlan}
          packageSignUpFee={packageSignUpFee}
          addons={addons}
        />
      </div>

      <div className="subtotal" css={css`${mq.sm} { width: 100%; }`}>
        <Subtotal
          signUpFee={signUpFee}
          billingPlan={billingPlan}
          packageSignUpFee={packageSignUpFee}
        />
      </div>


      { (hasValue(promoAmount) && !isZero(promoAmount)) ? (
        <div className="discounts" css={css`${mq.sm} { width: 100%; }`}>
          <Flex margin="1em 0em 0em 0em" directionSm="row" justifySm="space-between">
            <h3>Discounts</h3>
            { (billingPlan === 'monthly' || billingPlan === 'annual' ) ? (
              <span css={css`color: #327FEC;`}>-${ signUpFee - promoAmount }</span>
            ) : (
              <span css={css`color: #327FEC;`}>-${ packageSignUpFee - promoAmount }</span>
            ) }
          </Flex>

          <Addendum>$1 sign up fee applied to check out</Addendum>
        </div>) : ((hasValue(promoAmount) && isZero(promoAmount) ? (
          // handle 0 dollar promo codes from sitebuilders
          <div className="discounts" css={css`${mq.sm} {width: 100%;}`}>
          <Flex margin="1em 0em 0em 0em" directionSm="row" justifySm="space-between">
            <h3>Promotion:</h3>
            <span css={css`color: #327FEC;`}>{promoCode}</span>
          </Flex>
          </div>

        ) : (null))
        )
      }

      <Divider />

      <div className="dueToday" css={css`${mq.sm} { width: 100%; }`}>
        <DueToday
          promoAmount={promoAmount}
          billingPlan={billingPlan}
          signUpFee={signUpFee}
          packageSignUpFee={packageSignUpFee}
         />
      </div>

        <form onSubmit={onPromoApply}>
          <Flex directionSm="row" justifySm="space-between" css={css`align-items: center; ${(hasValue(promoAmount) && promoCode) ? 'margin-bottom: 2em;' : 'margin-bottom: 1em;'}`}>
            <InputGroup noMargin css={css`margin: 3em 0em 3em 0em; ${mq.sm} { width: 100%; } width: 75%; flex-grow: 1;`} hasError={invalidPromo || errors.promo_code || mustApplyPromoError}>
              <label>Promo code</label>
              <input
                disabled={hasValue(promoAmount)}
                name="promo_code"
                type="text"
                ref={register()}
                onKeyDown={handleUserKeyPress}
                onBlur={onPromoApply}>
              </input>

              { errors.promo_code && errors.promo_code.message && (
                <ErrorMessageContainer css={css`position: absolute; top: 1; bottom: 1; left: 0; right: 0;`}>
                  { errors.promo_code.message }
                </ErrorMessageContainer>
              )}

              { invalidPromo && (
                  <ErrorMessageContainer css={css`position: absolute; top: 1; bottom: 1; left: 0; right: 0;`}>
                    This promo code is invalid
                  </ErrorMessageContainer>
                )
              }

              { mustApplyPromoError && (
                <ErrorMessageContainer css={css`position: absolute; top: 1; bottom: 1; left: 0; right: 0;`}>
                  You must apply your promo code.
                </ErrorMessageContainer>
              )
              }

              { (hasValue(promoAmount) && promoCode) ? (
                <ErrorMessageContainer css={css`position: absolute; top: 1; bottom: 1; left: 0; right: 0; font-style: italic; width: 160%; `}>
                  Promo code { promoCode } has been applied!
                </ErrorMessageContainer>
              ) : (null)
              }

            </InputGroup>
            <InputGroup noMargin>
              <ContinueButton
                disabled={hasValue(promoAmount)}
                css={css`display: inline; margin: 0 !important; padding: 1.3em 2em; margin-left: 1.3em !important;`}
                onClick={!hasValue(promoAmount) ? () => {} : onPromoApply}
              >
                APPLY
              </ContinueButton>
            </InputGroup>
          </Flex>
        </form>

      <div className="spacer" css={css`display: block; height: 3em;`} />

      <div className="expectedMonthlyFees">
        <h3 css={css`text-align:center;`}>Expected Monthly Fees</h3>

        <Divider css={css`margin-top: 0.5em;`} light />
          <ExpectedMonthlyFees
            billingPlan={billingPlan}
            firstBillingDate={firstBillingDate}
            commitment={commitment}
          />

        { addons.fb &&
          <div css={css`${mq.sm} { width: 100%; }`}>
            <Flex directionSm="row" justifySm="space-between">
              <p>Minimum Facebook Marketing Budget</p>
              <h4>$200</h4>
            </Flex>

            <Addendum>(Variable)</Addendum>
          </div>
        }

        { addons.google &&
          <div css={css`${mq.sm} { width: 100%; }`}>
            <Flex directionSm="row" justifySm="space-between">
              <p>Minimum Google Marketing Budget</p>
              <h4>$300</h4>
            </Flex>

            <Addendum>(Variable)</Addendum>
            <Addendum>(You can increase this if needed)</Addendum>
            <Addendum>(First Payment on campaign start)</Addendum>
          </div>
        }

        { addons.traffic_blaster &&
          <div css={css`${mq.sm} { width: 100%; }`}>
            <Flex directionSm="row" justifySm="space-between">
              <p>Traffic Blaster</p>
              <h4>$300</h4>
            </Flex>
          </div>
        }

        { (displayMMFee !== null) &&
          <div css={css`${mq.sm} { width: 100%; }`}>
            <Flex directionSm="row" justifySm="space-between">
              <p>Managed Marketing Fee</p>
              <h4>${ displayMMFee }</h4>
            </Flex>

            <Addendum>(First Payment on campaign start)</Addendum>
            { addons.fb && addons.google && <Addendum>(Includes combined discount of $100)</Addendum> }
          </div>
        }

        { addons.geek_ai && getPackageAddonPrice(packages, billingPlan, "geek_ai") > 0 &&
          <div css={css`${mq.sm} { width: 100%; }`}>
            <Flex directionSm="row" justifySm="space-between">
              <p>Geek AI</p>
              <h4>${getPackageAddonPrice(packages, billingPlan, "geek_ai")}</h4>
            </Flex>
          </div>
        }

        <h4 css={css`margin-top: 3em;`}>Important Information</h4>

        <Addendum>
          <ul css={css`color: black; padding-left: 1.1em; li { padding-bottom: 0.5em; line-height: 24px; }`}>
            <li>This is a {commitment} month commitment. After the commitment period, it continues month-to-month.</li>
            { ((targetMarket) && (billingPlan === 'expand' || billingPlan === 'conquer')) &&
              <li>Your target metro is {targetMarket.label}.</li>
            }
            <li>Your site will not be created until you complete this order.</li>
            <li>Upon completing this order your card will be charged the sign-up fee.</li>
            <li>Your first prorated charge will be {(calculateProrationAmount(billingPlan, commitment, addons))}, billed 14 days from today on {(prorateDate())}.</li>
            <li>Your monthly fees will process in full on the first of each month after proration.</li>
            <li>Your monthly fees may include MLS fees and will vary depending on the number of users in your CRM.</li>

          </ul>
        </Addendum>
      </div>
    </Card>
  );
};
