/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { animateScroll as scroll } from 'react-scroll';
import Select from 'react-select';
import { useLocation } from 'wouter';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Flex from './Flex';
import InputGroup from '../../components/InputGroup';
import ClearButton from '../../components/ClearButton';
import ContinueButton from '../../components/ContinueButton';
import LoadingModal from '../../components/LoadingModal';
import { ErrorMessageContainer } from '../../components/Form';
import {
  useWizardActions,
  useWizardState,
  useWizardProps,
} from '../../state';

import { TerritorySelect, ZipCodeInput } from './LocationInputs';
import { COUNTRY_OPTIONS, TERRITORY_OPTIONS, ZIP_PATTERNS } from './addressConstants';
import {CheckBox} from "../BoardDetails/styled";


const defaultCountry = COUNTRY_OPTIONS[0]
const defaultTerritory = TERRITORY_OPTIONS[defaultCountry.value][0]

const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  input: styles => ({
    ...styles,
    padding: '1em',
    backgroundColor: 'transparent',
  }),
  placeholder: styles => ({
    ...styles,
    paddingLeft: '1em',
  }),
  option: styles => ({
    ...styles,
    paddingLeft: '1.5em',
  }),
  singleValue: styles => ({
    ...styles,
    paddingLeft: '1em',
  }),
  menu: styles => ({
    ...styles,
    zIndex: '2',
  }),
};

const stripeFormElementOptions = {
  style: {
    invalid: { color: '#f86621' }
  }
};

export default function PaymentForm({
  setValidatePromo,
  mobile,
  promoCode,
  onBackClick,
  mustApplyPromoError,
  setMustApplyPromoError,
}) {
  const {
    userDetails,
    billingPlan,
    siteInfo,
    mlsID,
    mlsBoard,
    mlsBoardState,
    addons,
    requiresBrokerInfo,
    commitment,
    targetMarket,
  } = useWizardState();

  const {
    opportunityID,
    orderUrl,
    packages,
  } = useWizardProps();

  const stripe = useStripe();
  const elements = useElements();

  const {
    nextStep,
    setVisitedHere,
    previousStep,
  } = useWizardActions();

  const [, setLocation] = useLocation();

  const { handleSubmit, register, errors, watch, control, setValue } = useForm({
    validateCriteriaMode: 'all',
    nativeValidation: true,
    mode: 'onBlur',
  });

  const [country, setCountry] = useState(defaultCountry);
  const [territory, setTerritory] = useState(defaultTerritory);

  const [stripeError, setStripeError] = useState({});
  const [cardNumberError, setCardNumberError] = useState(null);
  const [expiryError, setExpiryError] = useState(null);
  const [cvvError, setCvvError] = useState(null);

  const inputGroupBg = mobile ? 'white' : '#F9F9F9';
  const [billingDetails, setBillingDetails] = useState(null);

  const onCountryChange = useCallback((option) => {
    const newDefault = TERRITORY_OPTIONS[option.value][0]
    // setValue("state", newDefault);
    setTerritory(newDefault)
    return setCountry(option);
  }, []);

  const onTerritoryChange = useCallback((option) => {
    return setTerritory(option);
  }, []);

  const [tosAgree, setTosAgree] = useState(false);

  const orderButtonDisabled = useMemo(() => {
    const didSubmit = !!billingDetails;

    return didSubmit || !tosAgree;
  }, [
    billingDetails,tosAgree
  ]);



  const submissionHandler = async (d, evt) => {
    evt.preventDefault();

    const extraDetails = {
      name: d.full_name,
      address_line1: d.street_address_1,
      address_line2: d.street_address_2,
      address_city: d.city,
      // address_state: territory ? territory.value : territory,
      address_zip: d.zip_code,
    };

    if (country && country.value) {
      extraDetails.address_country = country.value;
      extraDetails.address_state = territory.value;
    }

    setBillingDetails(extraDetails);
  };

  const onSubmit = useMemo(() => handleSubmit(submissionHandler), [
    handleSubmit,
    submissionHandler,
    setValidatePromo,
    userDetails,
    promoCode,
  ]);

  useEffect(() => {
    if (billingDetails) {
      const cardNumberElement = elements.getElement(CardNumberElement);

      const createTokens = async () => {
        const { token, error } = await stripe.createToken(cardNumberElement, billingDetails);

        if (error) {
          setStripeError(error);
          throw new Error(error.message);
        }

        const { token: token2, error: error2 } = await stripe.createToken(cardNumberElement, billingDetails);

        if (error2) {
          setStripeError(error2);
          throw new Error(error2.message);
        }

        return {
          token,
          token2,
        };
      };

      const submitOrder = async (tokens) => {
        if (!(tokens.token && tokens.token2)) {
          throw new Error('No Stripe tokens provided to submitOrder');
        }

        if (Object.keys(stripeError).length > 0) {
          throw new Error(stripeError.message);
        }

        const { token, token2 } = tokens;

        const boardIsOther = mlsBoard.value === 'Other';

        // if billingPlan is in the packages object, then prepend 'package-' to the billingPlan
        const servicePlan = Object.keys(packages).includes(billingPlan) ? `package-${billingPlan}` : billingPlan;

        const orderPayload = {
          'first_name': userDetails.firstName,
          'last_name': userDetails.lastName,
          'phone': userDetails.phone_number,
          'email': userDetails.email.toLowerCase(),
          'agent_mls_id': mlsID,
          // company_mls_id - no clue where this one comes from
          'password': userDetails.password,
          'company': siteInfo.company_name,
          'company_name': siteInfo.company_name,
          'from_name': siteInfo.company_name,
          'city': billingDetails.address_city,
          'state': billingDetails.address_state,
          'additional_notes': siteInfo.additional_notes,

          /// For MCP
          'domain': siteInfo.site_domain,
          'template': 'miranda',
          'color_variation': '28-white-orange',
          'google_analytics_code': '', // NOTE: can be empty
          // 'board_id': mlsBoard.value.board_id,
          // TODO: the board id is the actual mcp board id. since i'm working locally for now this doesn't really apply
          'board_id': (boardIsOther) ? null : mlsBoard.value.default_mcp_board,
          'board_subscription_id': (!boardIsOther) ? mlsBoard.value.subscription_id : null,
          'seller_leads': true, // NOTE: this is for the valuation tool which i think is now always enabled by default
          'facebook_tool': true, // NOTE: this is enabled for everyone by default now

          /// for docusign
          'board_name': boardIsOther ? mlsBoard.mls_board_name : mlsBoard.value.name,
          // 'broker_name': , // TODO: ensure we are propagating the broker name and whatnot to the backend

          /// For LM - Company
          'time_zone': siteInfo.timezone.value,

          /// For Salesforce
          'email_signature': siteInfo.company_name,
          'google_marketing': addons.google,
          'facebook_marketing': addons.fb,
          'traffic_blaster': addons.traffic_blaster,
          'opportunity_id': `${opportunityID}`,
          'website': siteInfo.site_domain,
          'promo_code': promoCode,

          /// for subscriptions

          // NOTE: these come from stripe
          'token1': token.id,
          'token2': token2.id,

          'service_plan': servicePlan,
          'extraDetails': billingDetails,

          // Package
          'commitment': commitment,
          'geek_ai': addons.geek_ai,
          'solo_training': addons.solo_training,
          'target_market': targetMarket && targetMarket.value ? targetMarket.value['name'] : '',
          'target_market_id': targetMarket && targetMarket.value ? targetMarket.value['market_id'] : '',
          'target_market_tier': targetMarket && targetMarket.value ? targetMarket.value['tier'] : '',
        };

        $.post(JSON.parse(orderUrl), JSON.stringify(orderPayload)).done((response) => {
          if (requiresBrokerInfo) {
            setVisitedHere();
            setLocation('/broker');
          }

          else {
            nextStep();
          }
        }).fail((x) => {
          if (x.responseJSON && x.responseJSON.error) {
            // probably a stripe error
            if (x.responseJSON.error.message) {
            }

            setStripeError(x.responseJSON.error);
            scroll.scrollTo(100);
          }

          else {
            setStripeError({
              code: 'Server Error',
              message: 'There was an issue with charging your card. Please check your payment details and try again.'
            });
            scroll.scrollTo(100);
          }

          setBillingDetails(null);
        });
      };

      setTimeout(() => {
        createTokens().catch((err) => {
          setBillingDetails(null);
          scroll.scrollTo(100);
          // NOTE: if we don't throw, then it continues to submit the order
          throw err;
        }).then(submitOrder).catch((err) => {
          setBillingDetails(null);
          scroll.scrollTo(100);
        });
      }, 900);
    }
  }, [
    userDetails,
    billingDetails,
    siteInfo,
    mlsBoard,
    mlsBoardState,
    addons,
    opportunityID,
    promoCode,
    commitment
  ]);

  const onStripeChange = (event) => {
    const { error, elementType, complete } = event;

    if (error) {
      switch(elementType) {
        case 'cardNumber': {
          setCardNumberError(error);
          break;
        }

        case 'cardExpiry': {
          setExpiryError(error);
          break;
        }

        case 'cardCvc': {
          setCvvError(error);
          break;
        }

        default: {
          setStripeError(error);
          break;
        }
      }
    }

    else if (complete) {
      switch(elementType) {
        case 'cardNumber': {
          setCardNumberError(null);
          break;
        }

        case 'cardExpiry': {
          setExpiryError(null);
          break;
        }

        case 'cardCvc': {
          setCvvError(null);
          break;
        }

        default: {
          setStripeError(null);
          break;
        }
      }
      setStripeError({});
      setBillingDetails(null);
    }
  };

  const onTosAgreeCheckChange = (evt) => setTosAgree(!tosAgree);

  return (
    <form css={!mobile && css`margin-top: 8em; margin-left: 3em; width: 30vw;`} onSubmit={onSubmit}>
      <LoadingModal isOpen={!!billingDetails}>
        <h3 style={{ marginBottom: '1rem' }}>Processing your payment information...</h3>
        <img style={{ maxWidth: '60%' }} src="/static/images/loading-animation.gif" />
        <p style={{ padding: '0 1rem', marginBottom: '1rem' }}>Please do not refresh or leave the page, this should only take a second.</p>
      </LoadingModal>

      <ClearButton onClick={mobile ? onBackClick : previousStep} css={css`top: 2%;`}>
        <img src="/static/images/BackArrowGrey.svg" />
        <span>Back</span>
      </ClearButton>

      <h2 css={mobile ? css`text-align: center; padding-top: 3em; padding-bottom: 1em;` : css`margin-top: 2em; margin-bottom: 1em;`}>Card Information</h2>

      <InputGroup bg={inputGroupBg} inputPadding="1.1em" css={css`margin-bottom: 2em`}>
        <label>Full name</label>
        <input name="full_name" type="text" required ref={
          register({
            required: true,
          })
        }></input>
      </InputGroup>

      <InputGroup bg={inputGroupBg} hasError={!!cardNumberError || (stripeError.code && stripeError.code === 'card_declined')}>
        <label>Card Number</label>

        <div className="Stripe-Element">
          <CardNumberElement onChange={onStripeChange} options={stripeFormElementOptions}/>
        </div>

      </InputGroup>

      <InputGroup flex block={false} bg={inputGroupBg} css={css`justify-content: space-between; max-width: 100%;`}>
        <InputGroup flex inline bg={inputGroupBg} noMargin half={mobile} css={css`width: 50%;`} hasError={!!expiryError || (stripeError.code && stripeError.code === 'expired_card')}>
          <label>Month/Year</label>

          <div className="Stripe-Element">
            <CardExpiryElement onChange={onStripeChange} options={stripeFormElementOptions}/>
          </div>
        </InputGroup>

        <InputGroup flex inline bg={inputGroupBg} noMargin css={css`margin-left: 1em; width: 50%`} half={mobile} hasError={!!cvvError || (stripeError.param && stripeError.param === 'cvc')}>
          <label>CVC</label>

          <div className="Stripe-Element">
            <CardCvcElement onChange={onStripeChange} options={stripeFormElementOptions}/>
          </div>
        </InputGroup>

      </InputGroup>

      <ErrorMessageContainer>
        { Object.keys(stripeError).length > 0 && <p>{stripeError.message}</p> }

        {
          (stripeError.type && (stripeError.type === 'api_connection_error' || stripeError.type === 'api_error')) && (
            <React.Fragment>
              <p css={css`margin-bottom: 1em;`}>Click the button below to refresh the page.</p>
              <ContinueButton
                onClick={() => window.location.reload()}
              >
                  REFRESH
              </ContinueButton>
            </React.Fragment>
          )
        }

        { !!cardNumberError && <p>{cardNumberError.message}</p> }
        { !!expiryError && <p>{expiryError.message}</p> }
        { !!cvvError && <p>{cvvError.message}</p> }
      </ErrorMessageContainer>

      <h2 css={mobile ? css`text-align: center; padding-top: 1em; padding-bottom: 1em;` : css`margin-top: 2em; margin-bottom: 1em;`}>Billing Address</h2>

      <InputGroup hasError={errors.street_address_1} bg={inputGroupBg} css={css`margin-bottom: 1em;`}>
        <label>Street Address</label>
        <input name="street_address_1" type="text" required ref={
          register({
            required: true,
          })
        }></input>
      </InputGroup>

      <InputGroup bg={inputGroupBg} css={css`margin-bottom: 2em;`}>
        <input name="street_address_2" type="text" required={false} ref={
          register({
            required: false,
          })
        }></input>
      </InputGroup>

      <InputGroup flex bg={inputGroupBg} block={false} css={css`justify-content: space-between; max-width: 100%; margin-bottom: 2em;`}>
        <InputGroup bg={inputGroupBg} flex inline noMargin half={mobile} css={css`width: 45%;`}>
          <label css={css`z-index: 1;`}>Country</label>

          <Select
            name="country"
            options={COUNTRY_OPTIONS}
            styles={selectStyles}
            onChange={onCountryChange}
            defaultValue={defaultCountry} />
        </InputGroup>

        <InputGroup hasError={errors.city} bg={inputGroupBg} flex inline noMargin half={mobile} css={css`margin-left: 1em; width: 45%;`}>
          <label>City</label>
          <input name="city" type="text" required ref={
            register({
              required: true,
            })
          }></input>
        </InputGroup>
      </InputGroup>

      <InputGroup flex bg={inputGroupBg} block={false} css={css`justify-content: space-between; max-width: 100%;`}>
        <InputGroup hasError={errors.state} flex inline bg={inputGroupBg} half={mobile} noMargin css={css`width: 45%;`}>
          <TerritorySelect
            country={country}
            name="state"
            control={control}
            onChange={onTerritoryChange}
            required
            styles={selectStyles}
            defaultValue={defaultTerritory}
          />
        </InputGroup>

        <InputGroup hasError={errors.zip_code} flex inline bg={inputGroupBg} noMargin half={mobile} css={css`margin-left: 1em; width: 45%;`}>
          <ZipCodeInput name="zip_code" country={country} control={control} required />
        </InputGroup>
      </InputGroup>

      <Flex css={css`flex-direction: column; align-items: center; justify-content: center;`}>

        { !!mustApplyPromoError && (
          <ErrorMessageContainer>
            <p>You must apply your promo code</p>
          </ErrorMessageContainer>
        ) }
         <InputGroup flex block={false} bg={inputGroupBg} styleLabel={false} styleInput={false}>
          <CheckBox type="checkbox" onChange={onTosAgreeCheckChange} required name="mls-vendor-fees" checked={tosAgree} ref={
            register({
              required: true,
            })
          }/>
          <span>
            I have read and agree with the <a href="https://www.realgeeks.com/terms-of-use" target="_blank">Terms of Service</a> and <a href="https://www.realgeeks.com/privacy" target="_blank">Privacy Policy</a>
          </span>
        </InputGroup>
        <ContinueButton
          disabled={orderButtonDisabled}
          onClick={onSubmit}
          css={css`margin-bottom: ${mobile ? '3em' : '1em'} !important; margin-top: ${mobile ? '1em' : '3em'} !important;`}
        >
          PLACE ORDER
        </ContinueButton>
      </Flex>
    </form>
  );
};
